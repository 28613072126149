<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import productionPlanningServices from '../Script/ProductionPlanningServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionPlanningGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    // props: ['deleteClick','editClick','statusClick'],
    props: ['deleteClick','editClick'],
    data: function () {
        return  {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"production_planning\".\"status\"" }                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            return { 
                                query: productionPlanningServices.readProductionPlanningQuery(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionPlannings == null){
                            return [];
                        }else{
                            return response.data.GetProductionPlannings.production_plannings;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionPlannings == null){
                            return 0;
                        }else{
                            return response.data.GetProductionPlannings.total;
                        }
                    },
                    model: {
                        fields: {
                            production_planning_date: {type: "proddate"},
                            created_at: {type: "proddate"},
                            quantity: {type: "number"},
                            processing_time_estimation: {type: "number"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "production_planning_code", title: "Kode Perencanaan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "formula_name", title: "Formula", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_name", title: "Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "base_uom", title: "Satuan Dasar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "quantity", title: "Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "secondary_uom", title: "Satuan Konversi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= secondary_uom == null ? base_uom : secondary_uom #"},
                { field: "secondary_quantity", title: "Quantity Konversi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= secondary_uom == null ? quantity : secondary_quantity #" },
                { field: "processing_time_estimation", title: "Estimasi (Menit)", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "production_planning_date", title: "Tanggal Perencanaan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        // var statusClick = this.$props.statusClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.production_planning_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.production_planning_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            deleteClick(dataItem.production_planning_id);   
        })

        // //Status Form
        // GridElement.on("click", "#StatusButton", function (e){
        //     e.preventDefault();
        //     var dataItem = grid.dataItem(e.target.closest("tr"));
            
        //     statusClick(dataItem);
        // })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Production Planning")
        },
        detailInit: async function (e) {
            var data = await productionPlanningServices.getProductionPlanningQuery(e.data.production_planning_id);

            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "productionPlanningDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.production_planning_details);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'source_storage_name', title: "Gudang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'type_detail_name', title: "Tipe Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'material_code', title: "Kode Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'material_name', title: "Nama Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'base_uom', title: "Satuan Dasar", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'requested_quantity', title: "Request Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, editable: false, nullable: true },
                ],
            })
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: productionPlanningServices.readProductionPlanningQuery(),
                                variables : {
                                    paging : paging,
                                    status : status
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionPlannings == null){
                            return [];
                        }else{
                            return response.data.GetProductionPlannings.production_plannings;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionPlannings == null){
                            return 0;
                        }else{
                            return response.data.GetProductionPlannings.total;
                        }
                    },
                    model: {
                        fields: {
                            production_planning_date: {type: "proddate"},
                            created_at: {type: "proddate"},
                            quantity: {type: "number"},
                            processing_time_estimation: {type: "number"},
                        }
                    }
                },
              sort: { field: "created_at", dir: "desc" }
            });
        },
    },
}
</script>