<template>
    <div>
        <div class="modal fade" id="ProductionPlanningStatusModal" tabindex="-1" aria-labelledby="ProductionPlanningStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ProductionPlanningStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProductionPlanningCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import productionPlanningServices from '../Script/ProductionPlanningServices.js';
import { response } from '../../../../infrastructure/constant/response.js';
import { sweet_alert } from '../../../../infrastructure/constant/variable.js';
import moment from 'moment';

export default {
    name: 'ProductionPlanningStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : null,
            ProductionPlanningCode : null,
            Status : null,
            StatusData : [],

            Data : null,
        }
    },
    methods: {
        async statusClick(data){
            this.ModalTitle = 'Edit Status Production Planning: ';
            this.Id = data.production_planning_id;
            
            this.ProductionPlanningCode = data.production_planning_code;
            
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production_planning');
            this.Status = data.status;
            this.Data = data;
            
            window.$('#ProductionPlanningStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);
            var data = this.Data;
            
            //Grid
            var gridMaterial = productionPlanningServices.convertMaterialEditData(data.production_planning_details);
            var gridMaterialData = productionPlanningServices.convertMaterialSave(gridMaterial, data.production_planning_id);

            const productionPlanningData = {
                production_planning_id: data.production_planning_id,
                production_planning_code: data.production_planning_code,
                production_planning_date: moment(data.production_planning_date).format("YYYY-MM-DD"),
                status: this.Status,
                formula_id: data.formula_id,
                machine_id: data.machine_id,
                production_storage_id: data.production_storage_id,
                item_id: data.item_id,
                quantity: data.quantity,
                additional_time: {},
                production_planning_details: gridMaterialData
            }
            
            const variables = {
                        id : data.production_planning_id,
                        data : productionPlanningData
                    }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    productionPlanningServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#ProductionPlanningStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>