<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Perencanaan Produksi : {{ProductionPlanningCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Kode Perencanaan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionPlanningCode" v-model="ProductionPlanningCode" class="font-weight-bold"/>
                            <label id="errorProductionPlanningCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tanggal Perencanaan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="ProductionPlanningDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorProductionPlanningDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Machine" class="pb-3" :options="MachineData" v-model="Machine" @input="onChangeMachine()"/>
                            <label id="errorMachine" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Item</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Item" class="pb-3" :options="ItemData" v-model="Item" @input="onChangeItem()"/>
                            <label id="errorItem" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Formula</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Formula" class="pb-3" :options="FormulaData" v-model="Formula" @input="onChangeFormula()"/>
                            <label id="errorFormula" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Satuan Dasar</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="UOM" v-model="UOM" class="font-weight-bold" readonly/>
                            <label id="errorUOM" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Quantity</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="Quantity" v-model="Quantity" class="font-weight-bold form-control input-number" @change="quantityChange()" min="0"/>
                            <label id="errorQuantity" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Gudang Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Storage" class="pb-3" :options="StorageData" v-model="Storage" disabled/>
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Satuan Konversi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="UOMConversion" v-model="UOMConversion" class="font-weight-bold" readonly/>
                            <label id="errorUOMConversion" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Quantity Konversi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="QuantityConversion" v-model="QuantityConversion" class="font-weight-bold form-control input-number" @change="quantityConversionChange()" min="0"/>
                            <label id="errorQuantityConversion" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nilai Konversi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="ConversionAmount" v-model="ConversionAmount" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorConversionAmount" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">     
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Formula Detail</h4></label>
                            <hr>

                            <datasource ref="FormulaDetailDataSource" :data="this.FormulaDetailGridData" :page-size="20" :schema-model-fields="this.FormulaDetailGridDataSchemaModel"/>

                            <kendo-grid ref="gridFormulaDetail"
                                :data-source-ref="'FormulaDetailDataSource'"
                                :editable="false"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                            >

                                <kendo-grid-column  :field="'type_detail_id'"
                                                    :title="'Kode Tipe Detail'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'type_detail_name'"
                                                    :title="'Nama Tipe Detail'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'weight'"
                                                    :title="'Berat Formula'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'total_material'"
                                                    :title="'Berat Material'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'status'"
                                                    :title="'Status'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:center;'}"
                                                    :template= "this.formulaStatus"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>

                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">                            
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Material</h4></label>
                            <button class="btn btn-primary float-right" @click="addMaterialClick()" >
                                <i class="fa fa-plus"></i> Add
                            </button>
                            <hr>

                            <datasource ref="MaterialDataSource" :data="this.MaterialGridData" :page-size="10" :schema-model-fields="this.MaterialGridDataSchemaModel"/>

                            <kendo-grid ref="gridMaterial"
                                :data-source-ref="'MaterialDataSource'"
                                :editable="EditableValue"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true">

                                <!-- <kendo-grid-column  :field="'storage_id'"
                                                    :title="'Kode Gudang'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column> -->
                                                    
                                <kendo-grid-column  :field="'storage_name'"
                                                    :title="'Gudang'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <!-- <kendo-grid-column  :field="'type_detail_id'"
                                                    :title="'Kode Tipe Detail'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column> -->
                                                    
                                <kendo-grid-column  :field="'type_detail_name'"
                                                    :title="'Tipe Detail'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Material'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Material'"
                                                    :width="300"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'base_uom'"
                                                    :title="'Satuan Dasar'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'weight'"
                                                    :title="'Berat'"
                                                    :width="100"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'total'"
                                                    :title="'Berat Material'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :title="'Action'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :command="{ text: 'Delete', click: deleteMaterialClick }"
                                                    :width="200"></kendo-grid-column>
                            </kendo-grid>

                            <material-form ref="materialForm" :saveMaterialGrid="saveMaterialGrid"/>
                        </div>
                    </CRow>

                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/production-planning')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import moment from 'moment';
import productionPlanningServices from '../Script/ProductionPlanningServices';
import materialForm from './MaterialForm.vue';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'ProductionPlanningForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column' : GridColumn,
        'datasource' : DataSource,
        'material-form' : materialForm
    },
    async mounted () {
        if(this.FormType == 'Add'){
            this.EditableValue = true;
            document.getElementById('ProductionPlanningCode').readOnly = false;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            
            this.ProductionPlanningId = null;
            this.ProductionPlanningCode = 'Auto';
            this.ProductionPlanningDate = dateNow;

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.MachineData = await productionPlanningServices.getMachineQuery();
            this.Machine = null;
            this.ItemData = [];
            this.ItemDetailData = [];
            this.Item = null;
            this.FormulaData = [];
            this.Formula = null;
            this.StorageData = await productionPlanningServices.getStorageQuery();
            this.Storage = null;

            this.UOM = null;
            this.UOMConversion = null;
            this.Quantity = 0;
            this.QuantityConversion = 0;
            this.ConversionAmount = 0;

            this.SaveType = 'Add';
        } else {
            this.EditableValue = true;
            document.getElementById('ProductionPlanningCode').readOnly = true;

            var data = await productionPlanningServices.getProductionPlanningQuery(this.Id);
            
            this.ProductionPlanningId = this.Id;
            this.ProductionPlanningCode = data.production_planning_code;
            this.ProductionPlanningDate = data.production_planning_date;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production_planning');
            this.Status = data.status;
            this.MachineData = await productionPlanningServices.getMachineQuery();
            this.Machine = this.MachineData.find(c => c.value == data.machine_id);

            var machineData = await productionPlanningServices.getItemMachineQuery(data.machine_id);
            this.ItemData = machineData.items;
            this.ItemDetailData = machineData.itemsDetails;
            this.Item = this.ItemData.find(c => c.value == data.item_id);

            var formulaData = await productionPlanningServices.getFormulaItemQuery(data.item_id, "Approved");
            this.FormulaData = formulaData.header;
            this.FormulaDetail = formulaData.detail;
            this.Formula = this.FormulaData.find(c => c.value == data.formula_id);

            this.StorageData = await productionPlanningServices.getStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == machineData.storage);

            this.UOM = data.base_uom;
            this.UOMConversion = data.secondary_uom == null ? data.base_uom : data.secondary_uom;
            this.Quantity = data.quantity;
            this.QuantityConversion = data.secondary_uom == null ? data.quantity : data.secondary_quantity;
            this.ConversionAmount = data.secondary_uom == null ? 1 : data.secondary_conv_amount;

            //grid
            this.MaterialGridData = productionPlanningServices.convertMaterialEditData(data.production_planning_details);
            this.genereteFormulaDetail(false);

            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error: 0,

            ProductionPlanningCode: null,
            ProductionPlanningDate: null,
            ProductionPlanningId: null,
            StatusData: [],
            Status: null,
            MachineData: [],
            Machine: null,
            ItemData: [],
            ItemDetailData: [],
            Item: null,
            FormulaData: [],
            Formula: null,
            StorageData: [],
            Storage: null,

            UOM: null,
            UOMConversion: null,
            Quantity: 0,
            QuantityConversion: 0,
            ConversionAmount: 0,

            EditableValue: true,

            FormulaDetail: [],

            // Grid
            FormulaDetailGridData: [],
            FormulaDetailGridDataSchemaModel: {
                type_detail_id: { type: "string", editable: false },
                type_detail_name: { type: "string", editable: false },
                weight: { type: "number", editable: false },
                total_material: { type: "number", editable: false }
            },
            MaterialGridData: [],
            MaterialGridDataSchemaModel: {
                production_planing_detail_id: { type: "string", editable: false },
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                storage_id: { type: "string", editable: false },
                storage_name: { type: "string", editable: false },
                type_detail_id: { type: "string", editable: false },
                type_detail_name: { type: "string", editable: false },
                base_uom: { type: "string", editable: false },
                weight: { type: "number", editable: false },
                quantity: { type: "number", editable: false },
                total: { type: "number", editable: false },
            },

            SaveType: null,
        }
    },
    methods: {
        quantityChange(){
            var detailSelectedItem = this.ItemDetailData.filter(data => { return (data.item_id == this.Item.value); })[0];
            this.QuantityConversion = this.Quantity / detailSelectedItem.secondary_conv_amount;
            this.genereteFormulaDetail(true);
        },
        quantityConversionChange(){
            var detailSelectedItem = this.ItemDetailData.filter(data => { return (data.item_id == this.Item.value); })[0];
            this.Quantity = this.QuantityConversion * detailSelectedItem.secondary_conv_amount;
            this.genereteFormulaDetail(true);
        },
        formulaStatus(e){
            if(e.status == undefined){
                return "";
            }else{
                if(e.status == "Cukup"){
                    return "<div style='color: green'><b>" + e.status + "</b></div>";
                }else{
                    return "<div style='color: red'><b>" + e.status + "</b></div>";
                }
            }
        },
        async onChangeMachine(){
            if(this.Machine != null){
                var machineData = await productionPlanningServices.getItemMachineQuery(this.Machine.value);
                this.ItemData = machineData.items;
                this.ItemDetailData = machineData.itemsDetails;
                this.Storage = machineData.storage;
            }else{
                this.ItemData = [];
                this.ItemDetailData = [];
                this.Storage = null;
            }

            this.Item = null;
            this.FormulaData = [];
            this.Formula = null;
            this.FormulaDetailGridData = [];
            this.FormulaDetail = [];

            this.UOM = null;
            this.UOMConversion = null;
            this.Quantity = 0;
            this.QuantityConversion = 0;
            this.ConversionAmount = 0;
        },
        async onChangeItem(){
            if(this.Item != null){
                var formulaData = await productionPlanningServices.getFormulaItemQuery(this.Item.value, "Approved");
                this.FormulaData = formulaData.header;
                this.FormulaDetail = formulaData.detail;

                var detailSelectedItem = this.ItemDetailData.filter(data => { return (data.item_id == this.Item.value); })[0];

                this.UOM = detailSelectedItem.base_uom;
                this.UOMConversion = detailSelectedItem.secondary_uom;
                this.ConversionAmount = detailSelectedItem.secondary_conv_amount;
            }else{
                this.FormulaData = [];
                this.FormulaDetail = [];
                this.UOM = null;
                this.UOMConversion = null;
                this.ConversionAmount = 0;
            }
            
            this.Formula = null;
            this.FormulaDetailGridData = [];

            this.Quantity = 0;
            this.QuantityConversion = 0;
        },
        async onChangeFormula(){
            if(this.Formula != null){
                this.genereteFormulaDetail(false);
            }else{
                this.FormulaDetailGridData = [];
            }
        },
        genereteFormulaDetail(cellClose){
            var material = this.MaterialGridData;
            if(cellClose){
                material = this.$refs.gridMaterial.kendoWidget().dataSource._data;
            }
            
            if(this.Formula == null){
                this.FormulaDetailGridData = [];
            }else{
                this.FormulaDetailGridData = productionPlanningServices.genereteFormulaDetail(this.FormulaDetail, this.Formula.value, this.Quantity, material);
            }
        },
        addMaterialClick(){
            if(this.Formula == null || this.Formula == ''){
                this.$swal("Error", "Formula harus dipilih terlebih dahulu", "error");
            }else{
                if(this.Quantity == null || this.Quantity == '' || this.Quantity <= 0){
                    this.$swal("Error", "Quantity tidak boleh kosong", "error");
                }else{
                    if(this.Formula.value != 0){
                        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                    }
                    this.$refs.materialForm.addClick(this.Formula.value);
                }
            };
        },
        deleteMaterialClick: function(e) {
            e.preventDefault();
            var grid = this.$refs.gridMaterial.kendoWidget();
            var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
            
            var gridItem = grid.dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridItem, 'ProductionPlanning-MaterialGrid');
            var index = itemData.findIndex((obj => obj.item_id == dataItem.item_id && obj.storage_id == dataItem.storage_id));

            itemData.splice(index, 1);
            this.MaterialGridData = itemData;
            this.genereteFormulaDetail(false);
        },
        saveMaterialGrid(dataMaterial){
            var gridData = this.$refs.gridMaterial.kendoWidget().dataSource._data;
            var materialData = this.$globalfunc.objectToArrayConverter(gridData, 'ProductionPlanning-MaterialGrid');

            var duplicate = false;
            if(dataMaterial.length > 0){
                duplicate = productionPlanningServices.duplicateMaterialChecker(materialData, dataMaterial[0]);
            }
            
            if(duplicate){
                this.$swal("Error", "Material sudah ada", "error");
            }else{
                materialData = materialData.concat(dataMaterial);
                this.MaterialGridData = materialData;
                this.genereteFormulaDetail(false);
            }
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.ProductionPlanningCode == '' || this.ProductionPlanningCode == null){
                this.errorShow('errorProductionPlanningCode');
            }
            if(this.ProductionPlanningDate == '' || this.ProductionPlanningDate == null){
                this.errorShow('errorProductionPlanningDate');
            }
            if(this.Machine == '' || this.Machine == null){
                this.errorShow('errorMachine');
            }
            if(this.Item == '' || this.Item == null){
                this.errorShow('errorItem');
            }
            if(this.Formula == '' || this.Formula == null){
                this.errorShow('errorFormula');
            }
            if(this.Storage == '' || this.Storage == null){
                this.errorShow('errorStorage');
            }

            if(this.Quantity.toString() == '' || this.Quantity == null){
                this.errorShow('errorQuantity');
            }
            else if (this.Quantity < 0){
                this.errorShow('errorQuantity', response.fieldRequiredNotMinus);
            }

            var MaterialGridData = this.$refs.gridMaterial.kendoWidget().dataSource._data;
            if(MaterialGridData.length < 1){
                this.$swal("Error", "Material tidak boleh kosong", "error");
                this.Error++;
            }
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                //GridData
                var gridMaterial = this.$refs.gridMaterial.kendoWidget().dataSource._data;
                var gridMaterialData = productionPlanningServices.convertMaterialSave(gridMaterial, this.ProductionPlanningId);
                
                const productionPlanningData = {
                    production_planning_id: this.ProductionPlanningId,
                    production_planning_code: this.ProductionPlanningCode,
                    production_planning_date: this.ProductionPlanningDate,
                    status: this.Status,
                    formula_id: this.Formula.value,
                    machine_id: this.Machine.value,
                    production_storage_id: this.SaveType == 'Add' ? this.Storage : this.Storage.value,
                    item_id: this.Item.value,
                    quantity: this.Quantity,
                    additional_time: {},
                    production_planning_details: gridMaterialData
                }
                
                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : productionPlanningData
                    }
                    
                    productionPlanningServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/production-planning' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : productionPlanningData
                    }
                    
                    productionPlanningServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/production-planning'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    } 
}
</script>