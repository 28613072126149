import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionPlanningService {
    readProductionPlanningQuery(){
        var query = `
            query ($paging:ServerPaging, $status:[String!]) {
                GetProductionPlannings (Paging:$paging, Statuses:$status) {
                    production_plannings {
                        created_at
                        last_update
                        production_planning_id
                        production_planning_code
                        production_planning_date
                        status
                        formula_id
                        formula_name
                        machine_id
                        machine_name
                        production_storage_id
                        production_storage_name
                        item_id
                        item_name
                        base_uom
                        secondary_uom
                        secondary_conv_amount
                        quantity
                        secondary_quantity
                        additional_time
                        processing_time_estimation
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getProductionPlanningQuery(id){
        const variables = {
            id : [id]
        }
        var query = gql`
            query ($id:[Int!]) {
                GetProductionPlannings (ProductionPlanningIDs:$id) {
                    production_plannings {
                        created_at
                        last_update
                        production_planning_id
                        production_planning_code
                        production_planning_date
                        status
                        formula_id
                        formula_name
                        machine_id
                        machine_name
                        production_storage_id
                        production_storage_name
                        item_id
                        item_name
                        base_uom
                        secondary_uom
                        secondary_conv_amount
                        quantity
                        secondary_quantity
                        additional_time
                        processing_time_estimation
                        production_planning_details {
                            created_at
                            last_update
                            production_planing_detail_id
                            production_planning_id
                            source_storage_id
                            source_storage_name
                            material_id
                            material_code
                            material_name
                            type_detail_id
                            type_detail_name
                            requested_quantity
                            base_uom
                            weight
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProductionPlannings.production_plannings[0];
    }

    async getMachineQuery(){
        var query = gql`query{
            GetMachine{
                machine{
                    machine_id
                    machine_name
                    machine_code
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var machine = [];

        if(result.data.GetMachine != null){
            var data = result.data.GetMachine.machine;
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].machine_id, 
                    label: data[i].machine_code + ' (' + data[i].machine_name + ')'}
                machine.push(str);
            }
        }

        return machine;
    }

    async getItemMachineQuery(id){
        const variables = {
            id : id
        }

        var query = gql`query($id:Int){
            GetMachine(machineID:$id){
                machine{
                    storage_id
                    machine_item_list{
                        item_id
                        item_name
                        product_code
                        base_uom
                        secondary_uom
                        secondary_conv_amount
                    }           
                }
            }
        }`;

        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var items = [];
        var itemsDetails = [];
        var machineData = {
            storage: null,
            items: [],
            itemsDetails: []
        }

        if(result.data.GetMachine != null){
            var data = result.data.GetMachine.machine[0].machine_item_list;
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].item_id, 
                    label: data[i].product_code + ' (' + data[i].item_name + ')'}
                    items.push(str);

                var strDetail = {
                    item_id: data[i].item_id,
                    item_name: data[i].item_name,
                    product_code: data[i].product_code,
                    base_uom: data[i].base_uom,
                    secondary_uom: data[i].secondary_uom == null ? data[i].base_uom : data[i].secondary_uom,
                    secondary_conv_amount: data[i].secondary_uom == null ? 1 : data[i].secondary_conv_amount
                }
                itemsDetails.push(strDetail)
            }
            
            machineData = {
                storage: result.data.GetMachine.machine[0].storage_id,
                items: items,
                itemsDetails: itemsDetails
            }
        }
        
        return machineData;
    }

    async getFormulaItemQuery(id, status){
        const variables = {
            id : id,
            status : status
        }

        var query = gql`query($id:String, $status:String){
            GetFormula(ItemID:$id, Status:$status){
                Formula{
                    formula_id       
                    formula_name
                    formula_code
                    buffer_quantity_pct
                    formula_detail {
                        type_detail_id
                        type_detail_name
                        weight
                    }
                }
            }
        }`;

        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var formulaHeader = [];
        var formulaDetail = [];

        if(result.data.GetFormula != null){
            var formulaDetail = result.data.GetFormula.Formula;
            for (let i = 0; i < formulaDetail.length; i++) {
                var str = { value: formulaDetail[i].formula_id, 
                    label: formulaDetail[i].formula_code + ' (' + formulaDetail[i].formula_name + ')'}
                formulaHeader.push(str);
            }
        }

        const formulaData = {
            header : formulaHeader,
            detail : formulaDetail
        }
        
        return formulaData;
    }

    async getStorageQuery(){
        var query = gql`
            query{
                GetStorage{
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var str = { 
                value: result.data.GetStorage[i].storage_id, 
                label: result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'
            }
            storageData.push(str);
        }
        return storageData
    }

    readMaterialQuery(){
        var query = `query($paging:ServerPaging, $formulaId:Int!){
            GetFormulaItemList(Paging:$paging, FormulaId:$formulaId ) {
                        ItemStock {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        type_detail_id
                        type_detail_name
                        base_uom
                        weight
                        stock
                    }
                    Total
                }
            }`;
        return query;
    }

    itemDataObject(data){
        var dataItem = [{
            production_planing_detail_id: null,
            storage_id: data.storage_id,
            storage_name: data.storage_name,
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            base_uom: data.base_uom,
            weight: data.weight,
            quantity: data.quantity,
            total: data.quantity * data.weight,
        }];
        
        return dataItem;
    }

    convertMaterialEditData(data){
        var result = [];

        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    production_planing_detail_id: data[i].production_planing_detail_id,
                    storage_id: data[i].source_storage_id,
                    storage_name: data[i].source_storage_name,
                    item_id: data[i].material_id,
                    product_code: data[i].material_code,
                    item_name: data[i].material_name,
                    type_detail_id: data[i].type_detail_id,
                    type_detail_name: data[i].type_detail_name,
                    base_uom: data[i].base_uom,
                    weight: data[i].weight,
                    quantity: data[i].requested_quantity,
                    total: data[i].requested_quantity * data[i].weight
                }
                result.push(str);
            }
        }

        return result;
    }

    duplicateMaterialChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if((oldData[i].item_id == newData.item_id) && (oldData[i].storage_id == newData.storage_id)){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    convertMaterialSave(data, id){
        var result = [];

        for (let i = 0; i < data.length; i++) {
            var str = { 
                production_planing_detail_id: data[i].production_planing_detail_id, 
                production_planning_id: id,
                source_storage_id: data[i].storage_id,
                material_id: data[i].item_id,
                requested_quantity: data[i].quantity
            }
            result.push(str);
        }

        return result;
    }


    async addQuery(variables){
        var query = gql`
            mutation ( $data:NewProductionPlanning! ) {
                CreateProductionPlanning ( New:$data ){
                    message
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ( $id:Int!, $data:NewProductionPlanning! ) {
                UpdateProductionPlanning ( ID:$id, New:$data ){
                    message
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    genereteFormulaDetail(formulaDetail, formulaId, quantity, materialData){
        var result = []
        var data = formulaDetail.find(c => c.formula_id == formulaId);
        var holder = {};

        materialData.forEach(function(d) {
            if (holder.hasOwnProperty(d.type_detail_id)) {
                holder[d.type_detail_id] = holder[d.type_detail_id] + d.total;
            } else {
                holder[d.type_detail_id] = d.total;
            }
        });
            
        if(data.formula_detail.length != 0){
            var buffer = data.buffer_quantity_pct / 100;
            for (let i = 0; i < data.formula_detail.length; i++) {
                var totalMaterial = holder[data.formula_detail[i].type_detail_id]
                var weight = (data.formula_detail[i].weight + (data.formula_detail[i].weight * buffer)) * quantity;
                var status = '';

                if(weight <= totalMaterial && Math.ceil(weight) >= totalMaterial){
                    status = 'Cukup'
                }else if(Math.ceil(weight) < totalMaterial){
                    status = 'Berlebihan'
                }else{
                    status = 'Kurang'
                }

                var str = { 
                    type_detail_id: data.formula_detail[i].type_detail_id, 
                    type_detail_name: data.formula_detail[i].type_detail_name,
                    weight: weight,
                    total_material: totalMaterial == undefined ? 0 : totalMaterial,
                    status: status
                }

                result.push(str);
            }
        }
        
        return result;
    }
}

export default new ProductionPlanningService();